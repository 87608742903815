@import '../../../styles/sphera-colors.scss';

.Input-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.Label {
  font-size: 14px;
  font-weight: 500;
  color: $grey03;
  flex: 0 0 auto;
}

.Oval {
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
  border: 1px solid $grey03;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: $grey05;
  &:hover {
    background: $grey06;
  }
}

.Oval-Rotated {
  composes: Oval;
  transform: rotate(180deg);
}

.Play {
  width: 9px;
  height: 8px;
}

.Flex-Container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  justify-content: center;
  margin: 0 16px;
}

.Text-Input {
  border: 1px solid $grey05;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  font-size: 18px;
  line-height: 16px;
  color: $grey02;
  height: 48px;
  box-sizing: border-box;
  margin-left: 20px;
}

.Text-Input-Error {
  border: 1px solid $sphera-red;
}

.Labels-Container {
  position: absolute;
  width: 100%;
  top: 16px;
}

.Labels {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.Left-Label {
  position: absolute;
  left: 0px;
  top: 0px;
}

.Right-Label {
  position: absolute;
  right: 0px;
  top: 0px;
}

:global .FieldContainer {
  .rc-slider-handle {
    border-color: $sphera-blue;
    background: $sphera-blue;
  }
  .rc-slider-track {
    background-color: $sphera-blue;
  }
  .rc-slider-dot-active {
    border-color: $sphera-blue;
  }
}



