@import '../../../styles/sphera-colors.scss';

.LocationSelectorWithSiteMapTabBarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e8ec;
    margin: 16px 0px;
}

.LocationSelectorWithSiteMapTabBarLeft {
    display: flex;
    align-items: center;
}

.LocationSelectorWithSiteMapHelpContainer {
    height: 32px;
    display: flex;
    flex: 0 0 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.LocationSelectorWithSiteMapContainer .TabButtonBar {
    margin: 0px -8px;
}

.LocationMapContainer {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid $grey05;
    overflow: hidden;
}

.LocationMapContainer .atlas-map {
    width: 100% !important;
}

.LocationMapContainer .atlas-map-canvas {
    border-radius: 4px;
}

.LocationMapSearchBar {
    padding-bottom: 24px;
}

.LocationMapMap {
    background-color: #92c7e6;
}

.LocationSiteMap {
    width: 100%;
    height: 100%;
}

.LocationSiteMap .Spinner-Loading {
    height: 100%;
}