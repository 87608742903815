@import '../../styles/sphera-colors.scss';

.SubmitPage {
    height: 100%;
    background: linear-gradient(#242C67, #3c49aa);
    display: flex;
    flex-direction: column;
}

.SubmitPageHeader {
    flex: 0 0 96px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SubmitPageHeader .__react_component_tooltip {
    left: initial !important;
    right: 24px;
    top: 24px !important;
}

.SubmitPageHelpContainer {
    flex: 0 0 24px;
    padding-right: 48px;
    cursor: pointer;
}

.SubmitPageContent {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SubmitPageSubmittedIcon {
    background-color: #ffffff;
    background-image: url(../../icons/check-blue.svg);
    background-position: center;
    background-repeat: no-repeat;
    height: 160px;
    width: 160px;
    border-radius: 98px;
}

.SubmitPageStatus {
    color: #ffffff;
}

.SubmitPageTitle {
    flex: 0 1 auto;
    color: #ffffff;
    font-size: 32px;
    padding: 24px 24px 12px 24px;
    font-weight: 500;
    text-align: center;
}

.SubmitPageHelpText {
    flex: 0 1 auto;
    color: rgba(255,255,255,0.67);
    font-size: 18px;
    padding: 0px 24px;
    text-align: center;
}

.SubmitPageFooter {
    flex: 0 0 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.StartFullReportButton, .DoneButton {
    height: 72px;
    min-width: 196px;
    font-size: 16px !important;
}

.StartFullReportButton {
    box-shadow: none;
    color: #ffffff;
    border: 1px solid #ffffff33;
}

.DoneButton {
    margin: 0 48px 48px 12px;
    border: none;
}

@media screen and (max-width: 768px) {
    .SubmitPageFooter {
        flex: 0 0 84px;
    }
    
    .DoneButton {
        margin: 0px 12px 12px 12px;
    }

    .StartPageHelpContainer {
        padding-right: 32px;
    }
}

@media screen and (max-width: 380px) {
    .SubmitPageFooter {
        flex: 0 0 52px;
    }

    .DoneButton {
        padding: 12px 16px;
        font-size: 14px !important;
        font-weight: 500;
        height: initial;
        min-width: initial;
    }
}