@import '../../styles/sphera-colors.scss';

.Dropdown {
    display: flex;
    flex: 0 0 auto;
    align-items: flex-start;
    flex-direction: column;
    background: none repeat scroll 0 0 #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    margin-top: 40px;
    max-height: 200px;
    position: absolute;
    right: 0px;
    top: 0px;
    max-width: 80vw;
    min-width: 100px;
    z-index: 999;
    border:solid #cccccc 1px;
    border-radius: 4px;
  }
  
  .DropdownItem {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .DropdownItem:hover {
    background-color: $grey05;
  }