@import '../../../styles/sphera-colors.scss';
.ReviewPageFieldSummary {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 20px 50%;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto !important;
    grid-column-gap: 20px;
    grid-row-gap: 4px;
    margin-top: 8px;
}

.ReviewPageSummaryItemTitle {
    -ms-grid-column: 1;
    grid-column: 1 / 1;
    font-weight: 600;
}

.ReviewPageItems {
    padding-top: 12px;
}


.ReviewPageSummaryItemLabel {
    -ms-grid-column: 3;
    grid-column: 2 / 2;
}

.ReviewPageSummaryItemLabelNoAnswer {
    -ms-grid-column: 3;
    grid-column: 2 / 2;
    color: #8D99AE;
}

.ReviewPageQuestionFilterDropdown{
    position: absolute;
    top: 32px;
    left: 0px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    border-radius: 4px;
    margin-top: 4px;
}

.ReviewPageQuestionFilterDropdownRow{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 16px;
}


.ReviewPageQuestionFilterDropdownRow:hover {
    background-color: $grey05;
}

.ReviewPageQuestionFilterDropdownOption{
    color: $grey01;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.ReviewPageQuestionFilterDropdownOptionImg{
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReviewPageExpandCollapseText {
    display: flex;
    height: 32px;
    align-items: center;
    font-size: 14px;
    color: $sphera-blue;
    cursor: pointer;
}

.ReviewPageQuestionFilterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 32px;
}

.ReviewPageQuestionFilterSelectedOption{
    color: $sphera-blue;
    font-size: 14px;
    padding-right: 6px;
}

.ReviewPageOptionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.ReviewPageQuestionFilterCarat{
    height: 6px;
    display: flex;
}

@media print {

    .noPrint {
        display: none;
    }
}