.Wizard {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.WizardContent {
    flex: 0 1 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: auto;
}