@import '../../styles/sphera-colors.scss';

.TabButtonBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -8px;
    margin-right: -8px;
}

.TabButton {
    margin: 0px 8px;
    padding: 6px 0px;
    text-transform: uppercase;
    font-size: 14px;
    color: $grey03;
    box-sizing: border-box;
    font-weight: 600;
    cursor: pointer;
}

.TabButtonSelected {
    border-bottom: 2px solid $sphera-blue;
    color: $grey01;
}