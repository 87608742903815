.ChosenItem {
  display: flex;
  align-items: center;
  border: 1px solid white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #F0F0F0;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 18px;
  cursor: default;
}

.ChosenItem span {
  margin-right: 12px;
}

.ChosenItem button {
  position: relative;
  background: none;
  border: 0px;
  top: 2px;
  cursor: pointer;
}

.ChosenItemDisabled {
  border: 1px solid #e2e5ea;
  color: #555667;
  background-color: #f2f5f7;
}