@import '../../styles/sphera-colors.scss';

.LanguagePicker {
    position: relative;
}

.LanguagePickerButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 24px;
    cursor: pointer;
}

.LanguagePickerFlag {
    height: 32px;
    display: flex;
    align-items: center;
}

.LanguagePickerFlag img, .LanguagePickerDropdownFlag img {
    height: 15px;
}

.LanguagePickerLanguage {
    color: #ffffff;
    padding: 0px 8px;
    font-size: 14px;
}

.LanguagePickerLightBackground .LanguagePickerLanguage {
    color: $grey01;
}

.LanguagePickerCaret {
    height: 6px;
    display: flex;
}

.LanguagePickerDropdown {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    left: 0;
    bottom: 32px;
    z-index: 1;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    border-radius: 4px;
}

.LanguagePickerDropdownFlag {
    display: flex;
    align-items: center;
}

.LanguagePickerDropdownRow {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    cursor: pointer;
}

.LanguagePickerDropdownLanguage {
    color: $grey01;
    padding-left: 8px;
    font-size: 14px;
}

.LanguagePickerDropdownRow:hover {
    background-color: $grey05;
}
