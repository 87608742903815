@import '../../../styles/sphera-colors.scss';

.ReviewPageItemHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: solid 1px #c2c9d4;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.ReviewPageCollapseDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.ReviewPageSummaryMultiLevelContainer {
    margin-bottom: 6px;
}

:global .ReviewPageSummaryItemLevel .ReviewPageSummaryItemLevel {
    margin-top: 6px;
}

.ReviewPageLevelLabel {
    color: $grey03;
    font-size: 13px;
    margin-right: 6px;
}

.ReviewPageItemTitle {
    font-size: 16px;
    font-weight: 600;
    padding-right: 8px;
}

.ReviewPageItemEdit {
    font-size: 15px;
    color: $sphera-blue;
    cursor: pointer;
    margin-left: auto;
}

.ReviewPageItemContent {
    color: $grey01;
    font-size: 14px;
    padding-bottom: 20px;
    display: block;
}

.ReviewPageItemContentCollapsed {
    display: none;
}

.ReviewPageItemBodyMapPart {
    padding: 2px 0px;
}

.ReviewPageItemPrimaryText {
    font-size: 15px;
}

.ReviewPageItemSecondaryText {
    font-size: 14px;
    color: $grey03;
}

.ReviewPageRelatedDocuments {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
}

.ReviewPageRelatedDocument, .ReviewPageRelatedDocumentImage {
    height: 96px;
    width: 96px;
    display: flex;
    justify-content: flex-end;
    flex: 0 0 96px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 4px;
    border: 1px solid $grey05;
}

.ReviewPageRelatedDocument {
    background-color: #ffffff;
    background-size: 24px;
}

.ReviewPageRelatedDocumentCount {
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey04;
    border-radius: 12px;
    margin: 4px;
    font-size: 11px;
    color: #ffffff;
}

.ReviewPageCollapseIconSpan {
    padding-right: 8px;
}

.ReviewPageCollapseIcon {
    display: inline-block;
    vertical-align: middle;
    transition: transform 0.2s;
}

.ReviewPageCollapseIconCollapsed {
    transform: rotate(-90deg);
}

@media screen and (max-width: 768px) {
    .ReviewPageRelatedDocuments {
        margin-top: 0px;
    }
}

@media print {
    .noPrint {
        display: none;
    }
}
