@import url('https://rsms.me/inter/inter.css');
@import 'styles/sphera-colors.scss';

*:focus {
    outline: none;
}

html, body, #root {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $grey01;
}

input, textarea, select, button {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

input::-webkit-input-placeholder {
    line-height: normal !important;
}

button {
    cursor: pointer;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Modal {
    max-width: 90%;
    overflow-y: auto;
    max-height: 100%;
    height: auto;
}


.Modal-Content {
    overflow-y: inherit;
}

.Modal-Close {
    margin: 0px !important;
    height: 39px;
    width: 24px;
    flex: 0 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Modal-Title-Text {
    line-height: initial !important;
}

::-ms-clear {
    display: none;
}

.__react_component_tooltip {
    transition: none;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.__react_component_tooltip.type-light {
    color: $grey01;
}

.__react_component_tooltip.show {
    opacity: 1;
}

.__react_component_tooltip div {
    margin: 8px 0px;
    max-width: 400px;
    max-height: 400px;
    overflow: auto;
}

.__react_component_tooltip:after {
    display: none;
}

.__react_component_tooltip div a {
    color: $sphera-blue;
}

.__react_component_tooltip div > * {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.HelpTextModal .Modal-Header {
    border-bottom: none;
    padding: 16px 16px 0px 16px;
}

.HelpTextModal .Modal-Title {
    justify-content: flex-end;
}

.HelpTextModal .Modal-Title .Modal-Close {
    height: 14px;
    margin: 0px;
}

.HelpTextModal .Modal-Content, .HelpTextModal .Modal-Content strong {
    font-size: 14px !important;
}

.HelpTextModal .Modal-Content {
    padding: 16px !important;
    min-height: initial !important;
}

.HelpTextModal .Modal-Content a {
    color: $sphera-blue;
}

.HelpTextModal .Modal-Content ul {
    padding-left: 16px;
}

@media screen and (max-width: 768px) {
    .__react_component_tooltip {
        font-size: 13px;
    }

    .Modal-Close {
        height: 29px;
    }

    .Modal-Title-Text {
        font-size: 24px !important;
    }
}

@media print {
    html, body, #root {
    position: initial !important;
    height: initial !important;
    overflow: initial !important;
    }

    @page
    {
        size: auto;
        margin: 10mm 0 10mm 0;
    }

    body
    {
        margin: 0px;
    }
   }

