@import '../../../../styles/sphera-colors.scss';

.SiteMapPicker {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 12px;
}

.SiteMapPickerButton {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    height: 32px;
    cursor: pointer;
    border-bottom: 2px solid rgba(0,0,0,0);
    box-sizing: border-box;
}

.SiteMapPickerTitle {
    color: $sphera-blue;
    margin-right: 8px;
    font-size: 14px;
}

.SiteMapPickerCaret {
    height: 6px;
    width: 10px;
    display: flex;
}

.SiteMapPickerDropdown {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    left: 0;
    top: 32px;
    z-index: 1;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    border-radius: 4px;
}

.SiteMapPickerDropdownRow {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
}

.SiteMapPickerDropdownTitle {
    color: $grey01;
    font-size: 14px;
}

.SiteMapPickerDropdownRow:hover {
    background-color: $grey05;
}
