@import '../../../styles/sphera-colors.scss';

.DropdownMultiLevelContainer {
    display: flex;
    flex-direction: column;
}

.DropdownMultiLevelLabel {
    font-size: 14px;
    font-weight: 500;
    color: $grey03;
    margin: 14px 0px 6px 0px;
}