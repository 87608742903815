@import '../../styles/sphera-colors.scss';

.FieldContainer {
    padding: 6px 0px;
    margin-bottom: 8px;
}

.FieldLabel {
    font-size: 14px;
    font-weight: 500;
    color: $grey03;
}

.FieldMandatoryIndicator {
    color: $sphera-red;
}

.Text-Input, .Text-Area {
    width: 100%;
    box-sizing: border-box;
    font-size: 18px !important;
}

.Text-Input {
    height: 48px;
}

.Text-Area {
    resize: none;
    height: 292px;
}

.Text-Input:disabled,
.Text-Area:disabled,
.Dropdown-Disabled,
.ChosenItemDisabled,
.SearchBarContainerDisabled {
    border: 1px solid $grey04;
    color: $grey03;
    background: none;
    box-shadow: none;
}

.Dropdown-Disabled {
    border-radius: 4px;
}

.Dropdown-Disabled .Dropdown-Header,
.Dropdown-Disabled .Dropdown-Header-Selected {
    background: none;
    box-shadow: none;
    border: none;
}

.Dropdown-Disabled .Dropdown-Text {
    color: $grey03;
}

.Dropdown-Drawer, .Dropdown-Drawer-Show {
    position: relative;
}

.ButtonDatePickerContainer .react-calendar__month-view__days__day {
    font-weight: 400;
    line-height: 14px;
}

.Toggle img {
    height: 20px;
}

.Toggle-Label {
    position: relative;
    padding-left: 8px;
    font-size: 18px;
}

.TimePicker-Override input{
    color: $grey02;
}

.Time-Picker-Disabled {
    border: 1px solid $grey04;
    background: none;
}

.Time-Picker-Disabled-Value {
    color: $grey03;
}

.DocumentSelectRemove {
    background-color: rgba(0,0,0,0);
    border: none;
}

.ButtonSelectContainer {
    align-items: flex-start;
}

.ButtonSelectImageOption {
    background: none;
}

.ButtonSelectSelected .ButtonSelectImageContainer, .ButtonSelectTextOption.ButtonSelectSelected {
    overflow: visible;
}

.ButtonSelectTitle, .ButtonSelectHelpText {
    max-width: 100%;
    max-height: 40%;
    overflow: hidden;
}

.ButtonSelectTextOption {
    border: 1px solid $grey04;
}

@media screen and (max-width: 768px) {
    .CheckboxGroup, .RadioGroup {
        columns: 1 !important
    }
}

.RadioGroupItem, .CheckboxGroupItem {
    word-break: break-word;
    break-inside: avoid;
}

.RadioGroupItem img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.RadioGroupItem span {
    position: relative;
    top: -3px;
}

.Default-Checkbox {
    height: 20px;
}

.Default-Radio-Label, .Default-Checkbox-Label {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
}

.Dropdown-Container, .ChooseListItemContainer {
    box-sizing: border-box;
}

.Dropdown-Header, .Dropdown-Header-Selected {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid $grey05;
    margin-right: 0;
    box-sizing: border-box;
    min-height: 50px;
    height: initial;
}

.Dropdown-Drawer-Show {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
}

.Dropdown-Items-Container {
    position: relative;
    box-sizing: border-box;
    margin-top: -1px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
}

.Dropdown-Placeholder {
    color: $grey04;
}

.Dropdown-Icon {
    margin: 0px;
    padding: 12px 12px 12px 4px;
}

.Dropdown-Clear {
    height: 18px;
    width: 18px;
    padding: 8px;
}

.Dropdown-Item, .Dropdown-Item:hover {
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    height: auto;
    min-height: 28px;
    line-height: 24px;
}

.Dropdown-Error {
    border: none;
}

.Dropdown-Error .Dropdown-Header {
    border: 1px solid $sphera-red;
}

.Dropdown-Text {
    font-size: 18px;
    text-align: initial;
    align-items: center;
    height: initial;
    line-height: 24px;
    padding: 12px 0px;
    margin-top: 0px;
}

.FieldSpinner .Spinner-Loading {
    justify-content: flex-start;
}

.FieldSpinner .Spinner-Loading img {
    height: 42px;
}

.LabelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .LangLabel {
        padding: 0 16px 0 8px;
    }
}

.Checkbox-Container {
    display: flex;
    align-items: center;
}

.SearchBarInputContainer {
    min-width: initial;
}

.SearchBarInputContainer img {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
}

.ButtonSelectError .ButtonSelectedIcon {
    background-color: $sphera-red;
}

.ChooseListViewCheckbox {
  min-width: 48px;
  max-width: 48px;
}

.ChooseListViewTable th {
    padding: 0px 12px;
}

.ChooseListAdditionalItems {
    padding: 0px 12px;
}

.HasTextIndicator {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: $sphera-blue;
    margin-right: 16px;
}

.InputFilledContainer {
    display: flex;
    align-items: center;
}


