@import '../../styles/sphera-colors.scss';

:global .LoginBasicAuthPage {
    height: 100%;
    width: 100%;
    background: linear-gradient(#242C67, #3c49aa);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.LoginBasicAuthContainer {
    background: #ffffff;
    min-width: 300px;
    width: 25%;
    border-radius: 5px;
}

.LoginBasicAuthHeader {
    padding: 16px 16px 16px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid lightgray;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LoginBasicAuthCancel {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

:global .LoginBasicAuthContent .Text-Input {
    padding: 1px;
    margin-top: 5px;
    padding-left: 12px;
    font-size: 15px !important;
    font-weight: 500;
}

.LoginBasicAuthErrorContainer {
    color: $sphera-red;
    padding: 16px 20px 0px 20px;
    font-size: 13px;
}

.LoginBasicAuthUsernameContainer {
    padding: 20px;
    padding-bottom: 0;
}

.LoginBasicAuthPasswordContainer {
    padding: 20px;
    padding-bottom: 40px;
}

.LoginBasicAuthLabel {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 2px;
}

.LoginBasicAuthFooter {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    border-top: 1px solid lightgray;
}

.LoginBasicAuthSignInButton, .LoginBasicAuthSignInButton:active, .LoginBasicAuthSignInButton:focus {
    font-weight: 400;
}
