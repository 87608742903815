@import '../../styles/sphera-colors.scss';

.StartPage {
    height: 100%;
    background: linear-gradient(#242C67, #3c49aa);
    display: flex;
    flex-direction: column;
}

.StartPageHeader {
    flex: 0 0 96px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.StartPageHeaderRightSection{
    display: flex;
    align-items: center;
    flex-direction: row;
}

.StartPageHeader .__react_component_tooltip {
    left: initial !important;
    right: 24px;
    top: 24px !important;
}

.StartPageHelpContainer {
    flex: 0 0 24px;
    padding-right: 48px;
    cursor: pointer;
    align-items: center;
    display: flex;
}

.StartPageContent {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.StartPageStatus {
    color: #ffffff;
}

.StartPageTitle {
    flex: 0 1 auto;
    color: #ffffff;
    font-size: 28px;
    line-height: 30px;
    padding: 0px 24px;
    text-align: center;
}

.StartPageIncidentTypes {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    width: 100%;
}

.StartPageHelpText {
    color: rgba(255,255,255,0.67);
    font-size: 18px;
}

.StartPageIncidentTypes .StartPageHelpText {
    margin-top: 24px;
}

.StartPageFooter {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 12px 48px 48px 48px;
}

.ContinueExistingButton, .ContinueExistingButton:active, .ContinueExistingButton:focus,
.ContinueExistingButtonMobile, .ContinueExistingButtonMobile:active, .ContinueExistingButtonMobile:focus {
    display: flex;
    color: #ffffff;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    box-shadow: none;
    font-size: 16px !important;
    font-weight: 600;
    padding: 24px;
}

.ContinueExistingButtonMobile {
    padding: 12px 16px;
    font-size: 14px !important;
    font-weight: 500;
    display: none;
}

.StartPageLocationChooser {
    width: 700px;    
    flex: 0 1 auto;
    font-size: 28px;
    padding: 24px;
}

.StartPageLocationChooser .HierarchyLocationSelectorContainer [class^="HierarchyLookupStateless__DrawerDiv"]  {
    position: absolute;
}

.StartPageSpinnerContainer {
    min-height: 247px;    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
}

@media screen and (max-width: 768px) {
    .StartPageFooter {
        margin: 12px;
        align-items: center;
    }
    
    .StartPageHelpContainer {
        padding-right: 32px;
    }

    .StartPageSpinnerContainer {
        height: 144px;
    }
    
    .StartPageLocationChooser {
        width: 480px;
    }
}

@media screen and (max-width: 480px) {
    .ContinueExistingButton {
        display: none;
    }

    .ContinueExistingButtonMobile {
        display: flex;
    }

    .StartPageFooter .LanguagePickerButton {
        padding: 0px 8px;
    }    

    .StartPageLocationChooser {
        width: 320px;
    }
}