
.ReviewPageAccountContainer {
    display: flex;
    align-items: center;
}
.SummaryModal {
    height: 70vh;
    width: 50vw;

    &.Modal .Modal-Content {
        min-height: 20vh;
        .Spinner-Loading {
            height: 45vh;
        }
    }
}

