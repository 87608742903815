@import '../../../../styles/sphera-colors.scss';

.BodyMapPartRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.BodyMapPartSelectedContainer {
    flex: 0 1 100%;
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    align-items: center;
    cursor: pointer;
}

.BodyMapInjuryTypeContainer {
    flex: 0 0 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.BodyMapInjurySelected {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
}

.BodyMapInjurySelectedText {
    font-size: 14px;
    color: $sphera-blue;
}

.BodyMapInjurySelectedIcon {
    padding-left: 6px;
}

.BodyMapInjuryTypeDropdown {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e5ea;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 4px 0px;
    position: absolute;
    margin-top: 32px;
    cursor: pointer;
}

.BodyMapInjuryTypeDropdownItem {
    display: flex;
    flex-direction: row;
    padding: 4px 8px;
}

.BodyMapInjuryTypeDropdownItemCheckbox {
    padding-right: 4px;
}

.BodyMapInjuryTypeDropdownItemName {
    font-size: 14px;
}