@import '../../styles/sphera-colors.scss';

.CompanyLogoContainer {
    padding-left: 48px;
    display: flex;
    align-items: center;
}

.CompanyLogoSpheraLogo, .CompanyLogo {
    max-height: 32px;
    max-width: 112px;
}

.CompanyLogoSpheraLogoMobile {
    max-height: 42px;
}

.CompanyLogoSpheraLogoMobile {
    display: none;
}

.CompanyLogoSeparator {
    margin: 0px 16px;
    width: 1px;
    background-color: $grey04;
    height: 32px;
    opacity: 0.5;
}

.HeaderContent .CompanyLogoContainer {
    padding-left: 18px;
}

.LoginBasicAuthPage .CompanyLogoContainer {
    padding-left: 0px;
    margin-top: 10%;
    margin-bottom: 2%;
    flex: 0 0 auto;
}

.LoginBasicAuthPage .CompanyLogoSpheraLogo,
.LoginBasicAuthPage .CompanyLogoSpheraLogoMobile,
.LoginBasicAuthPage .CompanyLogo {
    max-width: 200px;
    max-height: 48px;
}

@media screen and (max-width: 768px) {
    .CompanyLogoContainer {
        padding-left: 32px;
    }

    .LoginBasicAuthPage .CompanyLogoSpheraLogo,
    .LoginBasicAuthPage .CompanyLogoSpheraLogoMobile,
    .LoginBasicAuthPage .CompanyLogo {
        max-width: 160px;
        max-height: 36px;
    }
}

@media screen and (max-width: 480px) {
    .CompanyLogoSpheraLogo {
        display: none;
    }

    .CompanyLogoSpheraLogoMobile {
        display: block;
    }

    .LoginBasicAuthPage .CompanyLogoSpheraLogo {
        display: block;
    }

    .LoginBasicAuthPage .CompanyLogoSpheraLogoMobile {
        display: none;
    }

    .LoginBasicAuthPage .CompanyLogoSpheraLogo,
    .LoginBasicAuthPage .CompanyLogoSpheraLogoMobile,
    .LoginBasicAuthPage .CompanyLogo {
        max-height: 28px;
    }
}