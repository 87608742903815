@import '../../../styles/sphera-colors.scss';

.AdditionalRecipientsHelpText {
    margin-bottom: 4px;
}

.AdditionalRecipientsError {
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: $sphera-red;
    display: flex;
    align-items: center;
}

.AdditionalRecipientsErrorPlaceholder {
    height: 24px;
}

:global .AdditionalRecipientsInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

:global .AdditionalRecipientsInputContainer .Text-Input-Container {
    flex: 0 1 100%;
}

.AdditionalRecipientsAdd {
    flex: 0 0 64px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.AdditionalRecipientsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    padding: 0px 16px;
}

.AdditionalRecipientsList {
    overflow-y: auto;
    max-height: 200px;
}

.AdditionalRecipientsRowRemove {
    height: 100%;
    flex: 0 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.AdditionalRecipientsRowRemove img {
    height: 16px;
    width: 16px;
}
