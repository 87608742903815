@import '../../../styles/sphera-colors.scss';

.SideMenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    flex: 0 0 36px;
    padding: 16px 0px;
}

.SideMenuItemContent {
    flex: 0 1 100%;
    display: flex;
    align-items: center;
    min-height: 18px;
}

.SideMenuItemIcon {
    flex: 0 0 18px;
    background-color: $grey05;
    height: 2px;
}

.SideMenuItemTitle {
    flex: 0 1 auto;
    padding-left: 6px;
    font-size: 14px;
    word-break: break-word;
}

.SideMenuItemSelected .SideMenuItemTitle {
    font-weight: 700;
    color: $grey01;
}

.SideMenuItemSelected .SideMenuItemIcon {
    background-color: $sphera-blue;
}

.SideMenuItemDisabled {
    cursor: initial;
}

.SideMenuItemDisabled .SideMenuItemTitle {
    color: #D4D8E0;
}

.SideMenuItemDisabled .SideMenuItemIcon {
    background-color: $grey07;
}

.SideMenuItemStatus {
    flex: 0 0 auto;
    padding-right: 12px;
    display: flex;
    align-items: center;
}