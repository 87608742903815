.FieldSpinner .Spinner-Loading {
  justify-content: flex-start;
  margin: 8px 4px;

  .CircularProgress-root {
    height: 20px !important;
    width: 20px !important;
  }
}

.SearchBarInputContainer .FieldSpinner .Spinner-Loading {
  margin: 8px 14px 8px 4px;
}
