.Text-Input {
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  line-height: 16px;
  color: #555667;
  padding: 14px 70px 14px 12px;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px!important;
}

.Text-Input:focus {
  border: 1px solid #009bde;
  border-radius: 4px;
  outline: none;
}

.Text-Input::placeholder {
  color: #c6ccd6;
  opacity: 1;
}

.Text-Input:-ms-input-placeholder {
  color: #c6ccd6;
}

.Text-Input:disabled {
  color: #555667;
  background-color: #f2f5f7;
}

.Text-Input-Container {
  position: relative;
  display: flex;
  max-width: 100%;
  align-items: center;
}

.Text-Input-Left-Icon {
  position: absolute;
  top: 0px;
  left: 12px;
}

.Text-Input-Right-Icon {
  position: absolute;
  top: 0px;
  right: 12px;
}

.Text-Input-Extra-Padding-Left {
  padding-left: 36px;
}

.Text-Input-Extra-Padding-Right {
  padding-right: 36px;
}

.Text-Input-Error,
.Text-Input-Error:focus {
  border: 1px solid #DD655C;
  box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
}

.Text-Input-Mic{
  height: 32px;
  cursor: pointer;
}

.Text-Input-Mic-InUse{
  height: 32px;
  cursor: not-allowed;
  transform: scale(1);
	animation: pulse 1s infinite;
}

@keyframes pulse {
	0% {
    transform: scale(0.7);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.7);
	}
}

.Text-Input-Icon-Container {
  position: absolute;
  right: 8px;
  align-items: center;
  display: flex;
  height: 10px;
}

.Text-Input-Icon-Container .FieldSpinner .Spinner-Loading {
  margin: 8px;
}

.Text-Input-Translate-Icon{
  float: right;
  height: 24px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
}
