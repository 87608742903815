@import '../../../styles/sphera-colors.scss';


.HeaderReportingUser {
    display: flex;
    align-items: center;
    padding: 0px 8px;
    margin: 0px 12px;
    cursor: pointer;
    justify-content: center;
    position: relative;
}

.HeaderReportingUserText {
    font-size: 14px;
    flex: 0 0 auto;
    color: #ffffff;
    padding-right: 12px;
}

.SideMenuReportingUserIcon, .HeaderReportingUserIcon  {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    background-color: rgba(0,0,0,0.21);
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SideMenuReportingUserIcon{
    background-color: $grey07;
}


.HeaderReportingUserDropdown {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    background: none repeat scroll 0 0 #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    margin-top: 40px;
    max-height: 200px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 999;
    border:solid #cccccc 1px;
    border-radius: 4px;
}

.HeaderReportingUserDropdownBoxText, .HeaderReportingUserLogoutText {
    color: #2B2D42;
    font-size: 14px;
    flex: 0 0 auto;
    margin-left: 12px;
}

.HeaderReportingUserDropdownBoxImg {
    display: flex;
    flex: 0 0 32px;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;
}

.HeaderReportingUserDropdownBoxButton:hover {
    background-color: $grey05;
}

.HeaderReportingUserDropdownBoxButton:hover {
    background-color: $grey05;
}

:global .SideMenuReportingUser{
    display: none;
}

.SideMenuReportingUserText{
    display: none;
}

.HeaderReportingUserLogoutText{
    display: none;
}

.HeaderReportingUserDropdownBoxButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px 0 8px;
    flex: 1 0 auto;
    height: 36px;
}

hr.solid {
    border-top: 1px solid $grey05;
    width: 100%;
  }

.HeaderReportingUserLogoutText{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

@media screen and (max-width: 768px) {
    :global .SideMenuReportingUser {
        display: flex;
        align-items: center;
        padding: 0px 8px;
        margin: 0px 4px;
        cursor: pointer;
        justify-content: center;
        position: relative;
    }

    .HeaderReportingUserText{
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .HeaderReportingUserLogoutText{
        display: block;
        text-align: right;
        padding-bottom: 10px;
    }

    .HeaderReportingUserDropdown {
        right: -48px;
    }
}
