@import '../../styles/sphera-colors.scss';

.ReviewPageSiteMapPreview {
    margin-top: 32px;
    position: relative;
}

.ReviewPageSiteMapPreviewPin {
    position: absolute;
    height: 36px;
    width: 36px;
}