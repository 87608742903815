@import '../../styles/sphera-colors.scss';

.SubPageSummary {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 24px 8px 24px 24px;
    margin-bottom: 12px;
    border: 1px solid #e2e5ea;
    position: relative;
}

.SubPageSummaryLeft {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
}

.SubPageSummaryTitle {
    color: $grey01;
    font-size: 20px;
    font-weight: 500;
}

.SubPageSummaryItem {
    color: $grey03;
    font-size: 14px;
    margin-top: 4px;
}

.SubPageSummaryRight {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SubPageSummaryOptions {
    flex: 0 0 48px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 8px;
}

.SubPageSummaryReviewButton {
    background: none;
    padding: 8px 16px;
    color: #009bde;
    border-radius: 4px;
    border: 1px solid #009bde;
    font-size: 14px;
    cursor: pointer;
}
