$grey01: #2B2D42;
$grey02: #555667;
$grey03: #8D99AE;
$grey04: #C6CCD6;
$grey05: #E2E5EA;
$grey06: #EBEDF0;
$grey07: #F2F5F7;
$white: #FFFFFF;

$sphera-blue: #009BDE;
$sphera-blue30: #A8DAEF;
$sphera-blue5: #E5F0F5;
$sphera-blue5-on-white: #F2FAFD;
$sphera-dark-blue: #005387;
$sphera-aqua: #A2D8CA;

$sphera-green: #33BB44;
$sphera-orange: #F5821F;
$sphera-red: #E53C3C;
$yellow: #FFE1A8;
$rating-red: #DD655C;
$rating-green: #6FD28C;
