@import '../../styles/sphera-colors.scss';

.ContinueDraftModal .Modal-Content {
  padding: 24px;
  min-height: 60px;
}

.ContinueDraftModal .Modal-Content strong {
  font-size: 18px;
}

.ContinueDraftModalDescription {
  display: block;
  margin-bottom: 8px;
}

.InvalidShortCodeWarning{
  height: 24px;
  color: $sphera-red;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
