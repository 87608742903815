@import '../../styles/sphera-colors.scss';

.IncidentTypeButton {
    margin: 24px 24px 0px 24px;
    cursor: pointer;
}

.IncidentTypeButtonImage {
    height: 196px;
    width: 196px;
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.IncidentTypeButtonFooter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 4px 0px;
    width: 196px;
}

.IncidentTypeButtonTitle {
    color: #ffffff;
    font-weight: 500;
}

.IncidentTypeButtonHelp {
    height: 18px;
    margin-left: 8px;
}

@media screen and (max-width: 768px) {
    .IncidentTypeButton {
        margin: 24px 12px 0px 12px;
    }

    .IncidentTypeButtonImage {
        height: 144px;
        width: 144px;
    }

    .IncidentTypeButtonFooter {
        width: 144px;
    }

    .IncidentTypeButton .__react_component_tooltip {
        max-width: 60vw;
    }
}
