@import '../../../styles/sphera-colors.scss';

.HierarchyLocationSelectorContainer * {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.HierarchyLocationSelectorContainer {
    cursor: pointer;
    margin-bottom: 8px;
}

.HierarchyLocationSelectorDisabled {
    border: 1px solid $grey04;
    color: $grey04;
    background: none;
    box-shadow: none;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    cursor: initial;
    padding: 14px 12px;
    line-height: 16px;
}

.HierarchyLocationSelectorDisabledNodeName {
    font-size: 18px !important;
}

.HierarchyLocationSelectorDisabledPath {
    font-size: 15px !important;
    margin-left: 16px;
}

.HierarchyLocationSelectorContainer div[class^="Dropdown__DropdownBox"] {
    border: 1px solid #e2e5ea;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    font-size: 14px;
    line-height: 16px;
    color: #555667;
    max-width: 100%;
}

.HierarchyLocationSelectorContainer div[class^="Dropdown__DropdownBox"]:focus {
    border: 1px solid $sphera-blue;
    border-radius: 4px;
    outline: none;
}

.HierarchyLocationSelectorContainer div[class^="Dropdown__DropdownBox"]::placeholder {
    color: #c6ccd6;
    opacity: 1;
    margin-left: 0px;
}

.HierarchyLocationSelectorContainer div[class^="Dropdown__DropdownBox"]:-ms-input-placeholder {
    color: #c6ccd6;
    margin-left: 0px;
}

.HierarchyLocationSelectorContainer div[class^="Dropdown__DropdownBox"]:disabled {
    border: 1px solid #e2e5ea;
    color: #555667;
    background-color: #f2f5f7;
}

.HierarchyLocationSelectorError div[class^="Dropdown__DropdownBox"],
.HierarchyLocationSelectorError div[class^="Dropdown__DropdownBox"]:focus {
    border: 1px solid #DD655C;
    box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
  }

.HierarchyLocationSelectorContainer div[class^="DropdownDisplay__DropdownText"] {
    display: flex;
    align-items: baseline;
    flex-direction: row;
}

.HierarchyLocationSelectorContainer div[class^="DropdownDisplay__DropdownText"] span:first-child
{
    font-size: 18px;
    line-height: initial;
}

.HierarchyLocationSelectorContainer div[class^="DropdownDisplay__DropdownText"] span:nth-child(2)
{
    margin-left: 16px;
    font-size: 15px !important;
}

.HierarchyLocationSelectorContainer img[class^="Dropdown__DropdownIcon"] {
    height: 8px;
    width: 14px;
}

.HierarchyLocationSelectorContainer img[class^="Dropdown__ClearIcon"] {
    height: 18px;
    width: 18px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyLookupStateless__DrawerDiv"] {
    position: relative;
    padding: 0px;
    margin-top: -1px;
    display: table;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyExplorer__OuterDiv"] {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.25);
    padding: 0px;
}

.HierarchyLocationSelectorContainer div[class^="SearchTextbox__TextBoxDiv"] {
    display: flex;
    align-items: center;
}

.HierarchyLocationSelectorContainer img[class^="SearchTextbox__SearchIcon"] {
    margin: 0px 12px;
    padding: 0px;
    left: 0px;
    top: 14px;
}

.HierarchyLocationSelectorContainer input[class^="SearchTextbox__TextBox"] {
    flex: 1 1 auto;
    padding: 0px 40px;
    font-size: 18px;
}

.HierarchyLocationSelectorContainer input[class^="SearchTextbox__TextBox"]:focus {
    padding: 0px 39px;
}

.HierarchyLocationSelectorContainer img[class^="SearchTextbox__ClearIcon"] {
    margin: 0px 12px;
    padding: 0px;
    right: 0px;
    top: 14px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyExplorer__SpinnerDiv"] {
    background: #ffffff;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyExplorer__ItemsDiv"] {
    padding-left: 0px;
    margin-right: 0px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyNode__SelectDiv"] {
    height: initial;
    padding: 0px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyNode__NodeDiv"] {
    padding: 0px;
    min-height: 44px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyNode__ImgDiv"] {
    width: 44px;
    height: 44px;
    margin: 0px;
    padding: 0px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyNode__ImgDiv"] img {
    width: 14px;
    height: 14px;
    padding: 15px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyNode__Placeholder"] {
    width: 0px;
    padding: 0px 6px;
}

.HierarchyLocationSelectorContainer span[class^="HierarchyNode__NodeText"],
.HierarchyLocationSelectorContainer span[class^="NodeTextWithPath__NodeText"] {
    font-size: 16px;
    font-weight: 600;
    padding: 4px 12px 4px 0px;
}

.HierarchyLocationSelectorContainer img[class^="HierarchyNode__ArrowImg"] {
    margin: 0px;
    padding: 15px 19px;
    height: 13px;
    width: 8px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyExplorer__PathDiv"] {
    min-height: 44px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
}

.HierarchyLocationSelectorContainer span[class^="ActionablePath__NodeName"] {
    font-size: 15px;
}

.HierarchyLocationSelectorContainer img[class^="ActionablePath__Delimiter"] {
    margin: 1px 0px 0px 0px;
    padding: 0px 12px;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyExplorer__ItemsDiv"] {
    max-height: initial;
    overflow: hidden;
}

.HierarchyLocationSelectorContainer div[class^="HierarchyExplorer__ContentDiv"] {
    max-height: 300px;
    overflow-y: auto;
}



@media screen and (max-width: 480px) {
    .HierarchyLocationSelectorContainer div[class^="Dropdown__DropdownBox"] {
        min-height: 58px;
    }

    .HierarchyLocationSelectorContainer div[class^="DropdownDisplay__DropdownText"] {
        flex-direction: column;
    }

    .HierarchyLocationSelectorContainer div[class^="DropdownDisplay__DropdownText"] span {
        padding: 4px 0px;
    }

    .HierarchyLocationSelectorContainer div[class^="DropdownDisplay__DropdownText"] span:nth-child(2)
    {
        margin-left: 0px;
    }
}