@import '../../styles/sphera-colors.scss';

.WizardPage {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    background-color: #f0f5f7;
    max-height: 100%;
    overflow-x: hidden;
}

.WizardPageContent {
    padding: 48px 228px 0 64px;
    flex: 0 1 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

:global .WizardPageSpinner {
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    display: flex;
    background-color:  #f0f5f7;
}

:global .WizardPageTitle {
    font-size: 32px;
    color: $grey01;
    font-weight: 500;
    padding-bottom: 8px;
    word-break: break-word;
}

:global .WizardPageHelpText {
    font-size: 15px;
    color: $grey03;
    padding-bottom: 8px;
    word-break: break-word;
}

:global .WizardPageElements {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
}

@media screen and (max-width: 1280px) {
    .WizardPageContent {
        padding: 48px 64px 0 64px;
    }
}

@media screen and (max-width: 768px) {
    .WizardPageContent {
        padding: 24px 24px 0 24px;
    }

    :global .WizardPageTitle {
        font-size: 20px;
    }
}
