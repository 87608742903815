.SingleSelectItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #F0F0F0;
  padding: 14px 20px;
  font-weight: 500;
  font-size: 18px;
  cursor: default;
}

.SingleSelectItem span {
  margin-right: 12px;
}

.SingleSelectItem button {
  position: relative;
  background: none;
  border: 0px;
  top: 2px;
  cursor: pointer;
}

.linkButton {
  color: #009bde;
  margin: 8px 0;
  font-size: 14px;
  border: none;
  background-color: rgba(255,255,255,0);
  outline: none;
  padding: 0;
}

.loadMoreContainer {
  display: flex;
  justify-content: center;
}
