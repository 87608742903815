.Modal .Modal-Content {
  padding: 24px;
  min-height: 60px;
}

.Modal .Modal-Content strong {
  font-size: 18px;
}

.Modal span {
  display: block;
}
