@import '../../styles/sphera-colors.scss';

.SideMenu {
    background-color: #ffffff;
    box-shadow: 1px 0px 7px 0px #d8d8d8;
    color: $grey03;
    flex: 0 0 240px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
}

.SideMenuTopSection {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.SideMenuHeader {
    flex: 0 0 auto;
    border-bottom: #f1f1f1 1px solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 48px;
}

.SideMenuMobileToggleButton {
    display: none;
    align-items: center;
    justify-content: center;
    flex: 0 0 64px;
    height: 64px;
    cursor: pointer;
}

.SideMenuHeaderTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 24px;
    min-width: 0;
}

.SideMenuHeaderTitle {
    color: $grey01;
    font-size: 15px;
}

.SideMenuHeaderDraftShortCode {
    font-size: 12px;
    background-color: $grey06;
    color: $grey01;
    padding: 2px 8px;
}

.SideMenuProgressBar {
    display: none;
    background-color: #e1e5e9;
    height: 4px;
}

.SideMenuProgress {
    width: 0%;
    height: 100%;
    background-color: #00cc5f;
}

.SideMenuContent {
    padding-top: 96px;
    max-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.SideMenuBottomSection {
    flex: 0 0 auto;
    margin: 24px 0px;
}

.SideMenuIconContainer {
    display: flex;
}

.SideMenuLocationIcon {
    display: none;    
}

:global .SideMenuIconContainer .Overlay {
    height: 100vh;
    width: 100vw;
}

@media screen and (max-width: 768px) {
    .SideMenu {
        transform: translateX(-100%);
        transition: transform .1s ease;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-width: 384px;
        z-index: 1001;
    }

    .SideMenuOpen,
    .SideMenuMobileToggleButton {
        display: flex;
        transform: translateX(0%);
    }

    .SideMenuHeaderTitleContainer {
        padding: 0px;
    }

    .SideMenuHeaderTitle {
        padding-left: 0px;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .SideMenuLocationIcon {
        display: flex;
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        background-color: $grey07;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 480px) {
    .SideMenu {
        max-width: 100%;
    }

    .SideMenuHeader {
        border-bottom: none;
    }

    .SideMenuProgressBar {
        display: block;
    }

    .SideMenuContent {
        padding-top: 12px;
    }

    .SideMenuLocationIcon {        
        display: flex;
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        background-color: $grey07;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}