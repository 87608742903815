@import '../../../styles/sphera-colors.scss';


.WizardPageRequiredFieldTextContainer {
    padding-bottom: 24px;
}

.WizardPageRequiredFieldTextIndicator {
    color: $sphera-red;
}

.WizardPageRequiredFieldText {
    color: $grey03;
    font-size: 13px;
    font-style: italic;
}

:global .WizardPageFoundEntitesTitle{
    font-size: 14px;
    font-weight: 500;
    color: #8D99AE;
}