@import '../../../../styles/sphera-colors.scss';

.BodyMapPartUnclickable {
    fill: #a7a9ac;
}

.BodyMapPartSelected {
    fill: $sphera-blue;
    cursor: pointer;
}

.BodyMapPart {
    fill: #555668;
    cursor: pointer;
}

.BodyMapPart:hover, .BodyMapPartSelected:hover {
    fill: $sphera-blue;
}

#face {
    stroke: #fff;
    stroke-miterlimit:10;
}