@import '../../styles/sphera-colors.scss';

.ElementContainer {
    padding: 6px 0px;
    margin-bottom: 8px;
}

.ElementLabel {
    font-size: 14px;
    font-weight: 500;
    color: $grey03;
}

.ElementMandatoryIndicator {
    color: $sphera-red;
}

.map-container {
    height: auto;
}