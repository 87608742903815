@import '../../../styles/sphera-colors.scss';

.WizardPageBreadcrumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.WizardPageBreadcrumbItem {
    text-transform: uppercase;
    color: $grey03;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

.WizardPageBreadcrumbItemSelected {
    font-weight: 600;
    color: $grey01;
}

.WizardPageBreadcrumbIcon {
    margin: 0px 8px;
    display: flex;
    align-items: center;
}

.WizardPageObjectAddButtons {
    margin-bottom: 24px;
}

.WizardPageObjectAddButton, .WizardPageObjectAddButton:focus, .WizardPageObjectAddButton:active {
    background-color: #e9edf0;
    background-image: none;
    border: 2px dashed #C6CCD6;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 18px 24px;
    box-shadow: none;
    width: 100%;
    margin-bottom: 8px;
}

.WizardPageObjectAddButtonIcon {
    height: 18px;
    width: 18px;
    margin-right: 12px;
}