.BodyMapContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.BodyMapImage {
    flex: 0 0 400px;
    margin-right: 48px;
    height: 376px;
}

.BodyMapPartListContainer {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e5ea;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    font-size: 15px;
    color: #555667;
    height: 400px;
}

.BodyMapPartListSearch input, .BodyMapPartListSearch input:focus {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 42px;
}

.BodyMapPartListSearch {
    box-sizing: border-box;
    flex: 0 0 auto;
}

.BodyMapPartListSearchLeftIcon {
    height: 18px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.BodyMapPartListSelected {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    border-bottom: 1px solid #e2e5ea;
    overflow-y: auto;
    max-height: 25%;
}

.BodyMapPartList {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 8px 0px;
}

.BodyMapPartContainer {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    align-items: center;
    cursor: pointer;
}

.BodyMapPartCheckbox {
    height: 16px;
    width: 16px;
}

.BodyMapPartName {
    padding-left: 12px;
}

@media screen and (max-width: 768px) {
    .BodyMapImage {
        display: none;
    }
}