@import '../../../styles/sphera-colors.scss';

.Text-Area-Container {
  position: relative;
  display: flex;
  max-width: 100%;
}

.Text-Area {
  border: 1px solid #e2e5ea;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 8px 12px;
  padding-right: 50px;
  line-height: 32px;
  color: #555667;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px !important;
  resize: none;
  height: 292px;
}

.Text-Area:focus {
  border: 1px solid $sphera-blue;
  border-radius: 4px;
  outline: none;
}

.Text-Input:disabled {
  border: 1px solid $grey04;
  color: $grey03;
  background: none;
  box-shadow: none;
}


.Text-Area::placeholder {
  color: #c6ccd6;
  opacity: 1;
}

.Text-Area:-ms-input-placeholder {
  color: #c6ccd6;
}

.Text-Area-Error,
.Text-Area-Error:focus {
  border: 1px solid #DD655C;
  box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
}

.Text-Area-Mic{
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-bottom: 6px;
}

.Text-Area-Mic-InUse{
  height: 32px;
  cursor: not-allowed;
  transform: scale(1);
	animation: pulse 1s infinite;
}

@keyframes pulse {
	0% {
    transform: scale(0.7);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.7);
	}
}
.Text-Area-Translate-Icon{
  height: 24px;
  width: 24px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
}

.Text-Area-Icon-Container {
  position: absolute;
  right: 8px;
  top: 10px;
  display: flex;
  flex-direction: column;
}
