@import '../../styles/sphera-colors.scss';

.GridContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border: 1px solid #E2E5EA;
    border-radius: 4px;
    overflow-x: auto;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    max-height: 200px;
}
.TableTitle {
    order: 0;
    margin: 0px 0px 0px 10px;
    padding: 5px 0px 5px 0px;
}

.GridTable {
    min-width: 100%;
    flex: 0 0 auto;
    width: auto;
    border-collapse: collapse;

    tr  {
        vertical-align: middle;
        border-top: 1px solid #E2E5EA;
    }

    th {
        vertical-align: middle;
        text-align: left; 
        height: 32px;
        padding: 2px 15px 2px;
    }
    td {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        vertical-align: middle;
        text-align: left; 
    }

    thead {
        font-weight: 500;
        font-size: 14px;
        cursor: default;
        height: 32px;
    }
}

.Primary-Button.Summary-Button, .Primary-Button.Summary-Button:focus {
    padding: 4px 4px;
    border-radius: 2px;
}