@import '../../../styles/sphera-colors.scss';

.ContinueDraftInputContainer{
    border: 1px solid #e2e5ea;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.05);
    color: #555667;
    position: relative;
}

.ContinueDraftInputContainer .SearchBarInputContainer{
    position: relative;
    padding: 8px 10px;
}

.ContinueDraftInputContainer .SearchBarInputContainer,
.ContinueDraftInputContainer .SearchBarInputContainer:focus,
.ContinueDraftInputContainer .SearchBarInputContainer:active {
    font-size: 18px;
    color: #555668;
}

.ContinueDraftInputContainer .SearchBarInput{
    width: 100%;
    box-sizing: border-box;
    font-size: 18px!important;
}

.ContinueDraftInputContainer .AccidentList{
    overflow-y: scroll;
    max-height: 200px;
    position: absolute;
    background-color: white;
    width: 350px;
    border-radius: 4px;
    border: 1px solid #e2e5ea;
    width: 100%;
}

.AccidentList{
    border: none;
}

.ContinueDraftModal, .ContinueDraftModal .Modal-Content {
    overflow: visible;
}

.ContinueDraftInputContainer .ContinueDraftModal .SearchBarInputContainer {
    border: none;
}

.ContinueDraftInputContainer .ChooseListItem{
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    cursor: pointer;
}

.ChooseListItem{
    width: 100%;
}

.ChooseListItemDelete{
    padding: 10px;
    cursor: pointer;
    height: 40px;
    width: 36px;
}

.ContinueDraftInputContainer .ChooseListItemContainer:hover {
    background-color: #A8DAEF;
    color: #555667;
}

.ContinueDraftInputIcons {
    display: flex;
    align-items: center;
}

.ContinueDraftInputIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.AccidentList .ChooseListItemContainer{
    display: flex;
}

.ContinueDraftDropdownIcon {
    height: 12px !important;
}

.EventMainInfo{
    display: flex;
    justify-content: space-between;
    padding: 0px 8px 4px 0px;
}

.EventMainInfo img{
 height: 15px;
}

.deleteRecordModal .Modal-Content {
    padding: 24px;
    min-height: 60px;
    font-size: 20px;
    line-height: 1.1;
  }

.EventMainInfo span {
    margin: 0px;
    padding: 0px 16px;
    font-size: 14px;
}

.EventTime span{
    font-size: 14px;
    padding: 0px 16px;
    margin: 0px;
    color: $grey04;
}
