@import '../../styles/sphera-colors.scss';

:global .ReportingUserContainer {
    display: flex;
    flex-direction: column;
}

:global .ReportingUserContainer .ButtonSelectImageContainer {
    background-repeat: no-repeat;
    background-color: #ffffff;
}

.ReportingUserManualInfoContainer {
    margin-top: 24px;
}

.ReportingUserManualError {
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: $sphera-red;
    display: flex;
    align-items: center;
}
