@import '../../styles/sphera-colors.scss';

.HeaderContainer {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
}

:global .HeaderContent {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #242C67;
    height: 56px;
}

.HeaderRightSection {
    flex: 1 1 auto;
    padding-right: 16px;
    display: flex;
    align-items: center;
}

.HeaderLocationText {    
    padding: 8px 12px;    
    font-weight: 500;
    line-height: 16px;
    margin: 0px 0px 0px 8px;    
    font-size: 14px;
    flex: 1 1 auto;
    color: #ffffff;    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    direction: rtl;
}

.HeaderLocationIcon  {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    background-color: rgba(0,0,0,0.21);
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeaderHelpContainer {
    margin: 0px 8px;
    height: 18px;
    cursor: pointer;
}

.HeaderActionButton, .HeaderActionButton:focus, .HeaderActionButton:active {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 0px 0px 0px 8px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;
}

.HeaderActionButton img {
    margin-right: 10px;
    height: 10px;
}

.HeaderActionButton span {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

.AddTaskButton {
    background-color: #31bb43;
}

.AddTaskButton:focus, .AddTaskButton:active {
    background-color: #31bb43;
}

.CancelButton {
    background-color: #2D5C79;
    color: #ffffff;
}

.CancelButton:focus, .CancelButton:active, .CancelButton:hover {
    background-color: #245370;
    color: #ffffff;
}

.SaveExitButton {
    background-color: #3f6f8c;
    color: #ffffff;
}

.SaveExitButton:focus, .SaveExitButton:active, .SaveExitButton:hover {
    background-color: #366582;
    color: #ffffff;
}

.HeaderMobile {
    display: none;
    background-color: #f1f5f7;
    justify-content: space-between;
    align-items: center;
}

.HeaderMobileMenuButton, .HeaderMobileRight {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    flex: 0 0 auto;
}

.HeaderMobileMenuButton {
    flex: 0 0 64px;
    width: 64px;
}

.HeaderMobileSaveExitButton, .HeaderMobileSaveExitButton:focus, .HeaderMobileSaveExitButton:active,
.HeaderMobileCancelButton, .HeaderMobileCancelButton:focus, .HeaderMobileCancelButton:active {
    display: flex;
    border: none;
    white-space: nowrap;
    background: none;
    border: none;
    box-shadow: none;
    flex: 0 0 auto;
    height: 64px;
    padding: 0px 12px;
    color: $sphera-blue;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
}

.HeaderMobileRight button:last-child {
    margin-right: 6px;
}

.HeaderMobileCancelButton {
    margin-left: 6px;
}

.HeaderMobileTitle {
    flex: 0 1 100%;
    display: flex;
    padding: 0px 16px;
    font-size: 18px;
    font-weight: 600;
    color: $grey01;
}

.HeaderProgressBar {
    background-color: #c2c9d4;
    height: 8px;
}

.HeaderProgress {
    width: 0%;
    height: 100%;
    max-width: 100%;
    background-color: #00cc5f;
}

@media screen and (max-width: 768px) {
    :global .HeaderContent {
        display: none;
    }

    .HeaderMobile {
        display: flex;
    }

    .HeaderProgressBar {
        background-color: #e1e5e9;
        height: 4px;
    }
}
