@import '../../styles/sphera-colors.scss';

.WizardPageFooter, .WizardPageFooterMobile {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

.WizardPageFooter .WizardPagePrevious, .WizardPageFooter .WizardPageNext {
    height: 72px;
    font-size: 16px !important;
    font-weight: 500;
}

.WizardPageFooter .WizardPagePrevious, .WizardPageFooter .WizardPagePrevious:focus, .WizardPageFooter .WizardPagePrevious:active {
    background: none;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    color: $sphera-blue;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid $sphera-blue;
}

.WizardPageFooterMobile .WizardPagePrevious, .WizardPageFooterMobile .WizardPagePrevious:focus, .WizardPageFooterMobile .WizardPagePrevious:active {
    color: $sphera-blue;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $sphera-blue;
    border-radius: 4px;
    background: none;
}

.WizardPagePreviousIcon {
    height: 16px;
}

.WizardPagePreviousText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 220px;
    line-height: 2;
}

.WizardPageFooter .WizardPageNext {
    min-width: 196px;
}

.WizardPageFooterMobile {
    display: none;
    background-color: #ffffff;
}

.WizardPageFooterMobile .WizardPagePrevious, .WizardPageFooterMobile .WizardPageNext {
    padding: 12px 16px;
}

@media screen and (max-width: 768px) {

    .WizardPageFooter {
        display: none;
    }

    .WizardPageFooterMobile {
        display: flex;
    }
}