@import '../../styles/sphera-colors.scss';

.ReviewPage {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    background-color: #f0f5f7;
    max-height: 100%;
}

.ReviewPageContent {
    padding: 48px 228px 0 64px;
    flex: 0 1 100%;
    overflow: auto;
}

.ReviewPageAccountHeader{
    display: flex;
    align-items: center;
}

.ReviewPageAccountContent{
    padding-left: 32px;
    line-height: 26px;
}

.ReviewPageCompanyLogo {
    display: none;
    max-height: 84px;
    max-width: 84px;
    margin-bottom: 12px;
}

.ReviewPageTitle {
    font-size: 32px;
    color: $grey01;
    font-weight: 500;
    flex: 0 1 auto;
    padding-right: 24px;
}

.ReviewPageHelpText {
    font-size: 15px;
    color: $grey03;
    padding-top: 8px;
    padding-bottom: 12px;
}


.ReviewPageExpandCollapseText {
    display: flex;
    height: 32px;
    align-items: center;
    font-size: 14px;
    color: $sphera-blue;
    cursor: pointer;
}

.ReviewPageEmailButton, .ReviewPageEmailButton:active, .ReviewPageEmailButton:focus{
    background: none;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    color: $sphera-blue;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid $sphera-blue;
    font-size: 14px;
    font-weight: 500;
}

.ReviewPageEmailButton img{
    margin-right: 8px;
    height: 16px;
}

.ReviewPagePrintButton, .ReviewPagePrintButton:active, .ReviewPagePrintButton:focus {
    margin-right: 8px;
    background: none;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    color: $sphera-blue;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid $sphera-blue;
    font-size: 14px;
    font-weight: 500;
}

.ReviewPagePrintButton img  {
    height: 16px;
    margin-right: 8px;
}

.ReviewPageFooter, .ReviewPageFooterMobile {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

.ReviewPageFooter .ReviewPagePrevious, .ReviewPageFooter .ReviewPageSubmit {
    height: 72px;
    font-size: 16px !important;
    font-weight: 500;
}

.ReviewPageFooter .ReviewPagePrevious, .ReviewPageFooter .ReviewPagePrevious:focus, .ReviewPageFooter .ReviewPagePrevious:active {
    background: none;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    color: $sphera-blue;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid $sphera-blue;
}

.ReviewPageFooterMobile .ReviewPagePrevious, .ReviewPageFooterMobile .ReviewPagePrevious:focus, .ReviewPageFooterMobile .ReviewPagePrevious:active {
    color: $sphera-blue;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $sphera-blue;
    border-radius: 4px;
    background: none;
}

.ReviewPagePreviousIcon {
    height: 16px;
}

.ReviewPageFooter .ReviewPageSubmit {
    min-width: 196px;
}

.ReviewPageFooterMobile {
    display: none;
    background-color: #ffffff;
}

.ReviewPageFooterMobile .ReviewPagePrevious, .ReviewPageFooterMobile .ReviewPageSubmit {
    padding: 12px 16px;
}

.ReviewPageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ReviewPageButtonContainer{
    display: flex;
    flex: 0 0 auto;
}

.ReviewPageAccountContainer {
    display: flex;
    align-items: center;
}

.ReviewPageAccountContainerManual{
    margin-top: 5px;
}

.ReviewPageQuestionFilterDropdownOptionImg{
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReviewPageAccountContainer span, .ReviewPageAccountContainerManual span{
    margin-left: 8px;
}

:global .ReviewPageHierarchyPathNode {
    color: $grey02;
    font-size: 13px;
    margin-bottom: 6px;
}

.ReviewPagePrintTitle {
    display: none;
    font-size: 32px;
    color: $grey01;
    font-weight: 500;
    padding-bottom: 8px;
}

.Watermark {
    display: none;
    font-size: 20px;
    text-align: center;
    color: $grey04;
    font-weight: 500;
    padding-bottom: 8px;
}

.ReviewPageAdditionalRecipients {
    height: 20px;
}

@media screen and (max-width: 1280px) {
    .ReviewPageContent {
        padding: 48px 64px 0 64px;
    }
}


@media screen and (max-width: 768px) {
    .ReviewPageContent {
        padding: 24px 24px 0 24px;
    }

    .ReviewPageHeader {
        margin-right: -8px;
        margin-bottom: 8px;
    }

    .ReviewPageTitle {
        font-size: 20px;
    }

    .ReviewPageFooter {
        display: none;
    }

    .ReviewPageFooterMobile {
        display: flex;
    }

    .ReviewPagePrintButton, .ReviewPagePrintButton:active, .ReviewPagePrintButton:focus,
    .ReviewPageEmailButton, .ReviewPageEmailButton:active, .ReviewPageEmailButton:focus{
        border: none;
    }

    .ReviewPagePrintButton img, .ReviewPageEmailButton img {
        height: 24px;
        margin-right: 0px;
    }

    .ReviewPagePrintButton span, .ReviewPageEmailButton span {
        display: none;
    }
}

@media print {
    
    .ReviewPageContent{

        background: none;
        padding: 48px 48px 0px 48px;
    }

    .ReviewPageTitle{
        display: none;
    }


    .ReviewPageSummaryItemTitle{
        max-width: 325px;
    }

    .ReviewPagePrintTitle, .ReviewPageCompanyLogo, .Watermark {
        display: block;
    }

    .noPrint {
        display: none;
    }
}