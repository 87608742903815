.risk-matrix {
    background-color: white;
    border-collapse: collapse;
    border-color: grey;
    border-spacing: 0;
    display: table;
    font-size: medium;
    table-layout: fixed;
    width: 100%;
    box-sizing: border-box;
}

.risk-matrix tr::after, ::before {
    box-sizing: border-box;
}

.risk-matrix td {
    height: 50px;
    border: 1px solid;
    text-align: center;
}

.risk-matrix th {
    height: 50px;
    border: 1px solid;
    font-weight: normal;
}

.risk-matrix th.no-border {
    border: none;
}

.risk-matrix th.bold-header {
    font-weight: bold;
}

.risk-matrix th.inter-column {
    width: 50px;
    border: none;
}

.risk-matrix th.header {
    height: 50px;
    width: 25%;
    border: 1px solid;
    text-align: center;
    padding: 10px;
}

.risk-matrix td.bold-header {
    font-weight: bold;
}

.risk-matrix td.inter-column {
    height: 50px;
    width: 50px;
    border: 1px solid;
    text-align: center;
}

.risk-matrix td.header {
    height: 50px;
    width: 25%;
    border: 1px solid;
    text-align: center;
    padding: 10px;
}

.infoCellStyle { 
    text-align: center; 
    min-width: 1px; 
    margin: auto;
    overflow-wrap: normal;
}

.dataCellStyle { 
    text-align: center;
}

.risk-matrix-error,
.risk-matrix-error:focus {
  border: 1px solid #DD655C;
  box-shadow: 0 1px 4px 0 rgba(221, 101, 92, 0.05);
  padding: 2px;
  border-radius: 4px;
}

.backgroundColorLabel {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    margin-top: 10px;
}

.backgroundColorContainer {
    height: 40px;
}

.backgroundColorDisplay {
    width: 20px;
    height: 20px;
    border: black solid 1px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    float: left;
}

.backgroundColorText {
    cursor: pointer;
    float: left;
    font-family: 'Roboto';
    font-size: 14px;
    margin-left: 10px;
    line-height: 40px;
}

